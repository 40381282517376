<!-- eslint-disable no-console -->
<script>
import Layout from "../../../layouts/main";
import PageHeader from "@/components/page-header-v2";
import appConfig from "@/app.config";
import api from "../../../../common/axios";
import PDFViewer from "@/components/pdfViewer";
/**
 * Blank page component
 */
export default {
  page: {
    title: "Logistic Detail",
    meta: [{ name: "description", content: appConfig.description }],
  },
  components: { Layout, PageHeader, PDFViewer },
  data() {
    return {
      config: {
        api: "logistic_company",
      },
      title: "Lihat Logistic Company",
      items: [
        {
          text: "Logistic Company",
          href: "/logistic",
        },
        {
          text: "Lihat Detail",
          href: "/logistic",
          active: true,
        },
      ],
      badge: "lc001",
      model: {
        industry: {},
      },
      title_modal: null,
      file_path_modal: null,
      reject_reason: null,
    };
  },
  methods: {
    changeStatus(status) {
      let _ = this;
      let data = { account_status: status };
      api
        .put(
          _.config.api + "/" + this.$route.params.id + "/update_account_status",
          data
        )
        .then(() => {
          _.$toast("Status account sukses diubah", {
            icon: "fas fa-check",
            type: "success",
          });
          _.getData();
        });
    },

    changeDocument(status) {
      let _ = this;
      if (status == "disetujui") {
        let data = { document_status: status };
        api
          .put(
            _.config.api +
              "/" +
              this.$route.params.id +
              "/update_document_status",
            data
          )
          .then(() => {
            _.$toast("Status Document sukses diubah", {
              icon: "fas fa-check",
              type: "success",
            });
            _.getData();
          });
      } else {
        this.$refs["show-modal-reject"].show();
      }
    },

    changeDocumentReject() {
      let _ = this;
      let data = {
        document_status: "ditolak",
        reject_reason: this.reject_reason,
      };
      api
        .put(
          _.config.api +
            "/" +
            this.$route.params.id +
            "/update_document_status",
          data
        )
        .then(() => {
          _.$toast("Status Document sukses diubah", {
            icon: "fas fa-check",
            type: "success",
          });
          this.$refs["show-modal-reject"].hide();
          _.getData();
        });
    },

    getData() {
      let _ = this;
      api.get(_.config.api + "/" + this.$route.params.id).then((response) => {
        _.model = response.data.data;
        _.badge = _.model.code;
      });
    },
    show_modal(path, index) {
      this.$refs["show-modal"].show();
      let _ = this;
      if (index == "deed_of_incorporation_path") {
        _.title_modal = "Deed of Incorporation";
        _.file_path_modal = path;
      } else if (index == "npwp_path") {
        _.title_modal = "NPWP";
        _.file_path_modal = path;
      } else if (index == "siup_path") {
        _.title_modal = "SIUP";
        _.file_path_modal = path;
      } else if (index == "skdp_path") {
        _.title_modal = "SDKP";
        _.file_path_modal = path;
      } else if (index == "nib_path") {
        _.title_modal = "NB";
        _.file_path_modal = path;
      }
    },

    replaced(data) {
      return data.replaceAll("_", " ");
    },
  },
  mounted() {
    let _ = this;
    _.getData();
  },
  $route(newVal, oldVal) {
    // TODO: react to navigation event.
    // params cotains the current route parameters
    if (newVal != oldVal) {
      let _ = this;
      _.getData();
    }
  },
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" :badge="badge" />
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <div class="row">
              <div class="col-md-12 mb-1">
                <h4>
                  Detail Account
                  <b-dropdown class="ml-3" variant="primary">
                    <template #button-content>
                      <span class="text-capitalize px-4">{{
                        model.account_status
                      }}</span>
                      <i class="mdi mdi-chevron-down"></i>
                    </template>
                    <b-dropdown-item href="#" @click="changeStatus('aktif')"
                      >Aktif</b-dropdown-item
                    >
                    <b-dropdown-item
                      href="#"
                      @click="changeStatus('tidak aktif')"
                      >Tidak Aktif</b-dropdown-item
                    >
                    <b-dropdown-item href="#" @click="changeStatus('suspend')"
                      >Suspend</b-dropdown-item
                    >
                  </b-dropdown>
                </h4>
              </div>
              <div
                class="col-md-4"
                style="border-right: 2px solid #333; border-radius: 2px"
              >
                <h5>{{ model.name }}</h5>
                <h6>{{ model.email }}</h6>
                <h6>{{ model.contact_person }}</h6>
                <h6>industri : {{ model.industry.name }}</h6>
              </div>

              <div
                class="col-md-4"
                style="border-right: 2px solid #333; border-radius: 2px"
              >
                <div class="ml-4">
                  <label class="mb-0 form-label font-weight-light"
                    >Alamat</label
                  >
                  <h6>{{ model.address }}</h6>
                  <label class="mb-0 form-label font-weight-light"
                    >Telepon</label
                  >
                  <h6>{{ model.phone }}</h6>
                </div>
              </div>

              <div class="col-md-4">
                <div class="ml-4">
                  <label class="mb-0 form-label font-weight-light"
                    >Tanggal Registrasi</label
                  >
                  <h6>{{ model.registration_at | moment("YYYY MMM DD") }}</h6>
                  <label class="mb-0 form-label font-weight-light"
                    >Aktifitas Terakhir</label
                  >
                  <h6>{{ model.last_activity | moment("YYYY MMM DD") }}</h6>
                </div>
              </div>

              <div class="col-md-12 mt-4">
                <h4>Bussiness Profile</h4>
                <div class="row">
                  <div class="col-md-5">
                    <div class="row">
                      <div class="col-md-5">
                        <h6>Pendapatan / Tahun</h6>
                        <h6>
                          {{ model.yearly_income | currency }}
                        </h6>
                      </div>
                      <div class="col-md-7">
                        <h6>Sertifikasi</h6>
                        <div>
                          <span
                            class="text-capitalize"
                            v-for="(i, index) in model.certificates"
                            :key="index"
                          >
                            <span v-if="index != 'other_detail'">
                              {{ i == true ? replaced(index) + "," : "" }}
                            </span>
                            <span v-if="index == 'other_detail'">
                              {{ i == "" ? i : " " }}
                            </span>
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="col-md-12 mt-4">
                <div class="row mt-2">
                  <div class="col-md-12">
                    <h4>
                      Dokumen Legalitas
                      <b-dropdown
                        class="ml-3"
                        variant="primary"
                        v-if="model.legal_documents"
                      >
                        <template #button-content>
                          <span class="text-capitalize px-2">{{
                            model.document_status
                          }}</span>
                          <i class="mdi mdi-chevron-down"></i>
                        </template>
                        <b-dropdown-item
                          href="#"
                          @click="changeDocument('disetujui')"
                          >Disetujui</b-dropdown-item
                        >
                        <b-dropdown-item
                          href="#"
                          @click="changeDocument('ditolak')"
                          >Ditolak</b-dropdown-item
                        >
                      </b-dropdown>
                    </h4>
                  </div>
                  <div class="col-md-12" v-if="model.legal_documents">
                    <div class="row">
                      <div
                        class="col-md-2 m-2 p-3"
                        v-for="(i, index) in model.legal_documents"
                        :key="index"
                        style="border: 1px solid #333; border-radius: 2px"
                      >
                        <h6
                          class="text-center"
                          v-if="index == 'deed_of_incorporation_path'"
                        >
                          Deed of Incorporation
                        </h6>
                        <h6 class="text-center" v-if="index == 'npwp_path'">
                          NPWP
                        </h6>
                        <h6 class="text-center" v-if="index == 'siup_path'">
                          SIUP
                        </h6>
                        <h6 class="text-center" v-if="index == 'skdp_path'">
                          SKDP
                        </h6>
                        <h6 class="text-center" v-if="index == 'nib_path'">
                          NIB
                        </h6>
                        <h6 class="text-center">
                          <h6 class="manual-link">
                            <a @click="show_modal(i, index)">Lihat</a>
                          </h6>
                        </h6>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-12" v-else>
                    <div
                      class="col-md-12 text-center border border-primary rounded"
                    >
                      <h5 class="p-4">Dokumen belum diupload</h5>
                    </div>
                  </div>
                </div>
              </div>

              <div class="col-md-12 mt-4">
                <b-button variant="light" to="/logistic">Kembali</b-button>
                <!-- <b-button
                  class="ml-3"
                  variant="secondary"
                  @click="changeStatus('aktif')"
                  >Setujui</b-button
                >
                <b-button
                  class="ml-3"
                  variant="danger"
                  @click="changeStatus('tidak aktif')"
                  >Tolak</b-button
                >
                <b-button
                  class="ml-3"
                  variant="warning"
                  @click="changeStatus('suspend')"
                  >Suspend</b-button
                >
                <b-button
                  class="ml-3"
                  variant="success"
                  @click="changeStatus('aktif')"
                  >Aktifkan</b-button
                > -->
              </div>
            </div>
          </div>
        </div>
      </div>
      <b-modal
        id="modal-center"
        centered
        ref="show-modal"
        size="lg"
        min-
        :title="title_modal"
        title-class="font-18"
        hide-footer
      >
        <div class="modal-body min-height-modal">
          <!-- <embed
            :src="
              file_path_modal != null
                ? file_path_modal
                : 'https://via.placeholder.com/450?text=File+Not+Found'
            "
            class="embed-classes"
          /> -->
          <PDFViewer :file="file_path_modal" />
        </div>
      </b-modal>

      <b-modal
        id="modal-center"
        centered
        ref="show-modal-reject"
        size="lg"
        title="Document Status"
        title-class="font-18"
        hide-footer
      >
        <div class="modal-body">
          <ValidationObserver v-slot="{ handleSubmit }">
            <b-form
              @submit.prevent="handleSubmit(changeDocumentReject)"
              class="form-horizontal"
            >
              <div class="row">
                <div class="col-md-12">
                  <ValidationProvider
                    name="Alasan Tolak"
                    rules="required"
                    v-slot="{ errors }"
                  >
                    <b-form-group
                      id="input-group-2"
                      label="Alasan Tolak"
                      label-for="input"
                    >
                      <textarea
                        class="form-control"
                        id="exampleFormControlTextarea1"
                        rows="4"
                        v-model="reject_reason"
                      ></textarea>
                      <p class="fs-6 text-danger">
                        {{ errors[0] }}
                      </p>
                    </b-form-group>
                  </ValidationProvider>
                </div>
                <div class="col-md-6">
                  <div class="form-group row">
                    <div class="col-sm-3">
                      <b-button type="submit" variant="primary" class="w-100"
                        >Tolak</b-button
                      >
                    </div>
                  </div>
                </div>
              </div>
            </b-form>
          </ValidationObserver>
        </div>
      </b-modal>
    </div>
  </Layout>
</template>
